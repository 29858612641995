var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        ref: _vm.$options.name,
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          headerTitle: "emailer2948",
          tooltipMsg: "manualforemaile",
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "PetaskDatetimeCreated", ascending: 0 },
          modelId: "PetaskId",
          showActionEdit: false,
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "customActions",
              fn: function (list) {
                return [
                  list.row.PetaskIsConfirmed == 0
                    ? _c("megau-button", {
                        attrs: {
                          classprop: "btn btn-success blue mr-1 ml-1",
                          icon: "check",
                          title: _vm.$t("confirmsendinge"),
                          titlePosition: "left",
                        },
                        on: {
                          handleClick: function ($event) {
                            return _vm.openConfirmModal(
                              list.row.PetaskId,
                              _vm.confirmTask
                            )
                          },
                        },
                      })
                    : _vm._e(),
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-customdanger ml-1",
                      icon: "trash-alt",
                      tooltip: _vm.$t("delete"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.openDeleteModal(list.row.PetaskId)
                      },
                    },
                  }),
                ]
              },
            },
            {
              key: "PetaskDatetimeCreated",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(_vm._f("dateTime")(list.row.PetaskDatetimeCreated))
                  ),
                ]
              },
            },
            {
              key: "RecipientType",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(
                      list.row.RecipientType
                        ? _vm.recipientTypeTexts[list.row.RecipientType].Text
                        : ""
                    )
                  ),
                ]
              },
            },
            {
              key: "Subscription",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(
                      list.row.Subscription
                        ? _vm.subscriptionTexts[list.row.Subscription].Text
                        : ""
                    )
                  ),
                ]
              },
            },
            {
              key: "IsTestTask",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row align-items-center justify-content-start",
                    },
                    [
                      _c("boolean-display", {
                        attrs: { boolValue: !list.row.IsTestTask },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "CountryId",
              fn: function (list) {
                return [
                  _c("country-flag", {
                    attrs: { countryId: +list.row.CountryId },
                  }),
                ]
              },
            },
            {
              key: "PetaskIsConfirmed",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row align-items-center justify-content-start",
                    },
                    [
                      _c("boolean-display", {
                        attrs: { boolValue: !!list.row.PetaskIsConfirmed },
                      }),
                      _c(
                        "b-badge",
                        {
                          staticClass: "emailer-recipients-trigger",
                          attrs: { id: "task-" + list.row.PetaskId },
                        },
                        [_vm._v(_vm._s(_vm.$t("recipients")))]
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "task-" + list.row.PetaskId,
                            placement: "auto",
                            triggers: "hover",
                            delay: "200",
                            "custom-class": "emailer-recipients",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _vm._v(_vm._s(_vm.$t("recipientsofema"))),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("div", {
                            staticClass: "emailer-recipients-container",
                            domProps: {
                              innerHTML: _vm._s(
                                (list.row.Recipients || "").replaceAll(
                                  ";",
                                  "<br/>"
                                )
                              ),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          false,
          411245273
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }