import i18n from '@/services/lang';

export const tableColumns = [
	{
		model: 'PetaskDatetimeCreated',
		i18n: 'created',
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'PetaskName',
		i18n: 'emailname',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'RecipientType',
		i18n: 'to2339',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'Subscription',
		i18n: 'sendingallowed',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
		filter: 'country',
	},
	{
		model: 'IsTestTask',
		i18n: 'realtask',
		sortable: true,
		filter: 'boolean',
	},
	{
		model: 'SenderName',
		i18n: 'sendersname3511',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'SenderEmail',
		i18n: 'sendersemail3512',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'EmailsCount',
		i18n: 'unsentemails',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'PetaskIsConfirmed',
		i18n: 'emailssendingst',
		sortable: true,
		filter: 'boolean',
	},
];
