<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:ref="$options.name"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="emailer2948"
		tooltipMsg="manualforemaile"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'PetaskDatetimeCreated', ascending: 0 }"
		modelId="PetaskId"
		:showActionEdit="false"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	>
		<template #customActions="list">
			<megau-button
				v-if="list.row.PetaskIsConfirmed == 0"
				classprop="btn btn-success blue mr-1 ml-1"
				icon="check"
				:title="$t('confirmsendinge')"
				titlePosition="left"
				@handleClick="openConfirmModal(list.row.PetaskId, confirmTask)"
			></megau-button>

			<megau-button
				classprop="btn btn-customdanger ml-1"
				icon="trash-alt"
				:tooltip="$t('delete')"
				@handleClick="openDeleteModal(list.row.PetaskId)"
			></megau-button>
		</template>

		<template #PetaskDatetimeCreated="list">{{ list.row.PetaskDatetimeCreated | dateTime }}</template>

		<template #RecipientType="list">{{ list.row.RecipientType ? recipientTypeTexts[list.row.RecipientType].Text : '' }}</template>

		<template #Subscription="list">{{ list.row.Subscription ? subscriptionTexts[list.row.Subscription].Text : '' }}</template>

		<template #IsTestTask="list">
			<div class="d-flex flex-row align-items-center justify-content-start">
				<boolean-display :boolValue="!list.row.IsTestTask"></boolean-display>
			</div>
		</template>

		<template #CountryId="list">
			<country-flag :countryId="+list.row.CountryId"></country-flag>
		</template>

		<template #PetaskIsConfirmed="list">
			<div class="d-flex flex-row align-items-center justify-content-start">
				<boolean-display :boolValue="!!list.row.PetaskIsConfirmed"></boolean-display>
				<b-badge :id="`task-${list.row.PetaskId}`" class="emailer-recipients-trigger">{{ $t('recipients') }}</b-badge>
				<b-popover
					:target="`task-${list.row.PetaskId}`"
					placement="auto"
					triggers="hover"
					delay="200"
					custom-class="emailer-recipients"
				>
					<template #title>{{ $t('recipientsofema') }}</template>
					<div v-html="(list.row.Recipients || '').replaceAll(';', '<br/>')" class="emailer-recipients-container"></div>
				</b-popover>
			</div>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import serviceCommon from '@/services/service/common.service';
import http from '@/services/helpers/http';
import serviceEnums from '@/services/service/enums.service';
import BooleanDisplay from '@/components/common/boolean-display';
import { setDropdownOptions } from '@/components/general/utils';

import { tableColumns } from './emailer.table-data';

export default {
	name: 'PortalEmailTaskTable',

	components: {
		TablePage,
		BooleanDisplay,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'PortalEmailTaskListItem',
			recipientTypeTexts: serviceEnums.getEnumDict('emailerRecipientType'),
			subscriptionTexts: serviceEnums.getEnumDict('emailerSubscription'),
		};
	},

	async created() {
		this.columns = await this.prepareColumns(tableColumns);
	},

	methods: {
		redirectToDetail(PetaskId = 0) {
			this.$router.push({
				name: links.emailerDetailName,
				params: { PetaskId },
			});
		},

		async prepareColumns(columns) {
			let cols = prepareRecipientType(columns);
			cols = prepareSubscription(cols);

			return cols;

			function prepareRecipientType(columns) {
				const values = serviceEnums
					.getEnumForDropdown('emailerRecipientType', true)
					.sort((v1, v2) => (v1.text > v2.text ? 1 : -1));

				return setDropdownOptions(columns, 'RecipientType', values);
			}

			function prepareSubscription(columns) {
				const values = serviceEnums.getEnumForDropdown('emailerSubscription', true);

				return setDropdownOptions(columns, 'Subscription', values);
			}
		},

		openDeleteModal(itemId) {
			serviceCommon.openDeleteModal(itemId, this.$modal, this.deleteItem);
		},

		deleteItem(id) {
			serviceCommon.deleteItem(id, this.tableGetData, this.controllerName);
		},

		openConfirmModal(itemId, callbackFnc) {
			this.$modal.show({
				title: this.$i18n.t('sendingemails'),
				text: this.$i18n.t('doyouwanttostar'),
				modalClass: 'modal-success',
				variant: 'success',
				okTitle: this.$i18n.t('oksendemails'),
				cancelTitle: this.$i18n.t('cancel2343'),
				onConfirm() {
					return callbackFnc(itemId);
				},
			});
		},

		async confirmTask(id) {
			await http.post('PortalEmailTask/ConfirmTask', id);
			this.tableGetData();
		},
	},
};
</script>

<style lang="scss">
.b-popover.emailer-recipients {
	min-width: 400px;

	.popover-body {
		padding: 10px;
	}
}

.emailer-recipients-container {
	overflow-y: auto;
	max-height: 700px;
}

.emailer-recipients-trigger {
	margin-left: 10px;
}
</style>
